/* eslint-disable prettier/prettier */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@/core/plugins/treeselect";
import "@mdi/font/css/materialdesignicons.css";
import moment from "moment";
import VueMoment from "vue-moment";
import "moment/locale/id";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import 'leaflet/dist/leaflet.css';

Vue.use(VueLoading)

Vue.filter('two_digits', function (value) {
  if (value.toString().length <= 1) {
    return "0" + value.toString();
  }
  return value.toString();
});

import vSelect from "vue-select";

Vue.component("vue-select", vSelect);
import "vue-select/src/scss/vue-select.scss";
import TinyMCE from "@/view/components/TinyMCE";
import mixin from "@/core/services/Helpers.js";
Vue.component("editor", TinyMCE);

import {
  ValidationProvider,
  ValidationObserver,
  extend
} from "vee-validate";
import {
  email,
} from 'vee-validate/dist/rules';

import user from "@/core/services/user.service";
extend("email", email)

extend("required", {
  mixins: [mixin],
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true,
  message: "The {_field_} field must be filled"
});

extend("between", {
  validate(value, args) {
    const min = args[0];
    const max = args[1];

    if (Number(min) <= value && Number(max) >= value) {
      return true;
    }

    return `{_field_} nilai hanya antara ${min} dan ${max}`
  },
  computesRequired: true,
},
  ["min", "max"]
);

Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);


Vue.use(VueMoment, {
  moment
});

import loadingOverlay from "@/core/plugins/loading-overlay";

Vue.prototype.$currentUser = user
Vue.prototype.$loadingOverlay = loadingOverlay

// API service init
ApiService.init();

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
