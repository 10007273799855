import ApiService from "@/core/services/api.service";
import JWTService from "@/core/services/jwt.service";

// Actions
export const GET_MAPEL = "getMapel";

// Mutation
export const SET_MAPEL = "setMapel";
export const SET_ERROR = "setError";

const state = {
    mapelList: [],
    errors: null
};

const getters = {
    currentMapelList(state) {
        return state.mapelList;
    },
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    [GET_MAPEL](context, payload) {
        const user = JSON.parse(JWTService.getToken())
        return new Promise((resolve, reject) => {
            ApiService.get(user.user.level==1?"siswa/mapel":"ajar")
            .then(({ data }) => {
                context.commit(SET_MAPEL, data.data);
                resolve(data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data.message);
                reject();
            });
        });
    },
};

const mutations = {
    [SET_MAPEL](state, mapel_list) {
        state.mapelList = mapel_list;
    },
    [SET_ERROR](state, errors) {
        state.errors = errors;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
