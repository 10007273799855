import Vue from 'vue'

// import {h} from 'vue';

const loadingOverlay = {
    show: () => Vue.$loading.show({
        // Pass props by their camelCased names
        // container: this.$refs.loadingContainer,
        // canCancel: true, // default false
        // onCancel: this.yourCallbackMethod,
        color: '#4CC5BD',
        loader: 'spinner',
        width: 64,
        height: 64,
        // backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 999,
    },
    // {
    //     // Pass slots by their names
    //     default: h('your-custom-loader-component-name'),
    //     }
    ),
}

export default loadingOverlay
